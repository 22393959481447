<template>
    <div  class="mt-3 stage-inner-container-padded">
        <b-row v-if="loading">
            <b-col>
                <h4>Loading Fulcrum Issue Failures</h4>
            </b-col>
        </b-row>
        <b-row v-else>
            <b-col cols="12">
                <h4>Fulcrum Issue Failures: {{rows.length}}</h4>
            </b-col>
            <hr>
            <b-col cols="12">
                <b-button-toolbar key-nav aria-label="Selection Options">
                    <b-dropdown class="mx-1" right text="Select">
                        <b-dropdown-item @click="selectAll">All Rows</b-dropdown-item>
                        <b-dropdown-item @click="deselectAll">Unselect All Rows</b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown v-if="selected.length > 0" class="mx-1" right text="Batch Processing">
                        <b-dropdown-item @click="attemptFix">Attempt To Fix</b-dropdown-item>
                    </b-dropdown>
                </b-button-toolbar>

                <b-progress v-if="showProgress" :max="progressMax" height="2rem">
                    <b-progress-bar :value="progressCurrent">
                        <span>{{progressTitle}} <strong>{{ progressCurrent }} / {{ progressMax }}</strong></span>
                    </b-progress-bar>
                </b-progress>
            </b-col>
            <b-col cols="12">
                <b-row>
                    <b-col cols="12">
                        <b-table
                        ref="FulcrumIssueFailureList"
                        striped
                        :small="true"
                        :items="rows"
                        :fields="fields"
                        selectable
                        select-mode="multi"
                        selected-variant="primary"
                        @row-selected="onRowSelected"
                        style="white-space: pre-line;"
                    >
                    <template v-slot:cell(device_id)="data">
                        <span><b>Device:</b> {{data.item.device_id}}</span><br>
                        <span><b-link v-b-popover.hover.left="'View This Device (Opens In New Tab)'" target="_blank" :to="getDeviceLink(data.item.device_id)">View This Device (Opens In New Tab)</b-link></span>
                    </template>
                    <template v-slot:cell(fulcrumID)="data">
                        <span>{{data.item.fulcrumID}}</span><br>
                        <span><b-link v-if="data.item.fulcrumID != null" v-b-popover.hover.left="'View This Record In Fulcrum (Opens In New Tab)'" target="_blank" :href="'https://web.fulcrumapp.com/records/' + data.item.fulcrumID">View This Record In Fulcrum (Opens In New Tab)</b-link></span>
                    </template>
                    
                    </b-table>
                    </b-col>
                </b-row>
            </b-col>
            
        </b-row>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');
const _ = require('underscore');

export default {
    name: 'BulkFixFulcrumIssuesFailures',
    components:{

    },
    props:{

    },
    data(){
        return{
            loading: true,
            error: false,
            errorMessage: '',

            // Actual Data
            rows: [],
            fields: [
                { key: 'added_on', label: "Attempted On", sortable: true },
                { key: 'added_by', label: "Attempted By", sortable: true },
                { key: 'failed_description', label: "Failure Description", sortable: true },
                { key: 'formName', label: "Fulcrum Form", sortable: false },
                { key: 'device_id', label: "Device", sortable: true },
                { key: 'result', label: "Repair Result", sortable: false },
                { key: 'fulcrumID', label: "Fulcrum ID", sortable: false},
                { key: 'actions', label: "Actions", sortable: false},
            ],
            selected: [],
            showProgress: false,
            progressTitle: '',
            progressCurrent: 0,
            progressMax: 0,
        }
    },
    methods:{
        setProgress(title, cur, total){
            this.progressTitle = title;
            this.progressMax = total;
            this.progressCurrent = cur;
        },
        onRowSelected(items){
            this.selected = items
        },
        getDeviceLink(deviceID){
            var defaultFormForType = this.$store.getters.getAvailableFormsByInspectionType({inspecting: 'devices'});
            if(defaultFormForType == null){
                return '.'
            }else if(defaultFormForType.length == 0){
                return '.'
            }else{
                return '/home/form/' + defaultFormForType[0].id + '/' + deviceID
            }
        },
        selectAll(){
            this.$refs.FulcrumIssueFailureList.selectAllRows();
        },
        deselectAll(){
            this.$refs.FulcrumIssueFailureList.clearSelected();
        },
        attemptFix(){
            this.showProgress = true;
            var items = this.selected;
            this.geocodeNextWithGoogle(items, 0);
        },
        geocodeNextWithGoogle(selection, index){
            this.setProgress("Geocoding Records", index + 1, selection.length);
            var itm = selection[index];
            this.performGoogleGeocode(itm,()=>{
               index++;
                if(selection.length - 1 >= index){
                    this.geocodeNextWithGoogle(selection, index);
                }else{
                    this.showProgress = false;
                }
            });
        },
        performGoogleGeocode(itm, completedCallback){
            var fixObj = {
                formID: itm.app_id,
                deviceID: itm.device_id
            };
            //Get User Token and Fetch The Values Required For This Page
            butils.instance.post(process.env.VUE_APP_API_BASE_URL + '/atils/fulcrum/attemptfailedissuerecovery.json', fixObj)
            .then(async (response) => {
                console.log(response);
                if(response.data.result.fixed[0]){
                    itm.fulcrumID = response.data.result.fixed[0].fulcrum_id;
                    if(itm.fulcrumID != null){
                        // Run the request reimport
                        butils.instance.get(process.env.VUE_APP_API_BASE_URL + `/webhooks/attempt_reimport/${itm.fulcrumID}`)
                        .then((resp)=>{
                            itm.result = "Webhook Reimport Attempt Completed";
                        })
                        .catch((err)=>{
                            if(butils.isError401(err)){
                                butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                                itm.result = "Error Durring Request Reimport Response 401 - Try Logging In Again";
                            }else if(butils.isError403(err)){
                                butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                                itm.result = "Error Durring Request Reimport Response 403 - Insufficient Permissions";
                            }else if(butils.isError409(err)){
                               itm.result = `Error Durring Request Reimport`;
                            }else{
                                if(_.has(err, 'response')){
                                    butils.createToast(this, `Error Durring Request Reimport`, `Fix Completed Succesfully, Request Reimport Failed For Device ID: ${itm.device_id}`, 'danger', 30);
                                    itm.result = `Error Durring Request Reimport`;
                                    this.error = true;
                                }else{
                                    console.log(err);
                                    itm.result = "An Unknown Error Occured";
                                    butils.createToast(this, itm.result, 'Check the console for more info', 'danger');
                                }
                            }
                            if(completedCallback != null){
                                itm.result = "Not Fixed";
                                completedCallback();
                            }
                        })
                    }
                    if(completedCallback != null){
                        itm.result = "Not Fixed";
                        completedCallback();
                    }
                }else if(completedCallback != null){
                    itm.result = "Not Fixed";
                    completedCallback();
                }
                
            })
            .catch((err) => {
                console.log(err)
                if(butils.isError401(err)){
                    butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                    itm.result = "Session Load Response 401 - Try Logging In Again";
                }else if(butils.isError403(err)){
                    butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                    itm.result = "Session Load Response 403 - Insufficient Permissions";
                }else if(butils.isError409(err)){
                    itm.result = "Cannot Fix Automatically\nCannot Match Records In Fulcrum\nMore Than One Candidate Record Exists.\nMust Be Fixed Manually";
                }else{
                    if(_.has(err, 'response')){
                        butils.createToast(this, `Request To Fix Failed`, `Failed Durring Fix Attempt For Device ID: ${itm.device_id}`, 'danger', 30);
                        itm.result = `Error Durring Fix`;
                        this.error = true;
                    }else{
                        console.log(err);
                        itm.result = "An Unknown Error Occured";
                        butils.createToast(this, itm.result, 'Check the console for more info', 'danger');
                    }
                }
                if(completedCallback != null){
                    completedCallback();
                }
            });
        },
    },
    watch:{

    },
    computed:{

    },
    beforeCreate(){

    },
    created(){

    },
    beforeMount (){

    },
    mounted(){
        var fulcrumForms = this.$store.getters.getFulcrumFormStore;
        console.log(fulcrumForms)
        butils.instance.get(process.env.VUE_APP_API_BASE_URL + `/atils/fulcrum/issued/failures.json`)
        .then((resp)=>{
            this.loading = false;
            resp.data.result.forEach((row)=>{
                let match = _.find(fulcrumForms.forms, (form)=>{ return form.id == row.app_id; })
                row.fixMe = false;
                row.formName = match.name;
                row.result = null;
                row.added_on = butils.formatters.timestampToDateTime(row.added_on, true);
                row.fulcrumID = null;
                this.rows.push(row);
            })
        })
        .catch((err)=>{
            this.error = true;
            if(butils.isError401(err)){
                butils.createToast(this, 'Logged Out', 'Login Again', 'warning');
                this.errorMessage = "Session Load Response 401 - Try Logging In Again";
            }else if(butils.isError403(err)){
                butils.createToast(this, 'Request Failed Due To EPC Restrictions', 'Your user lacks the permissions required to make this request, please contact your administrator to receive permissions to perform this action.', 'danger');
                this.errorMessage = "Session Load Response 403 - Insufficient Permissions";
            }else{
                if(_.has(err, 'response')){
                    var resp = err.response;
                    this.errorMessage = resp.errors;
                }else{
                    console.log(err);
                    this.errorMessage = "An Unexpected Error Occured Durring Session Load";
                    butils.createToast(this, this.errorMessage, 'Check the console for more info', 'danger');
                }
            }
        })
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){

    }
}
</script>

<style scoped>
    
</style>